import { STORES } from "constant"
import { useStore } from "stores"

export const formatPrice = (price: string | number) => {
  const { currency, rate } = useStore.getState().store || STORES[0]
  let formattedPrice = typeof price !== "number" ? (price as any) * 1 : price
  formattedPrice = formattedPrice * rate

  return formattedPrice
    .toLocaleString(undefined, {
      style: "currency",
      currency,
      // currencyDisplay: "narrowSymbol",
    })
    .replace("US$", "$ ")
}

export const customFetch = (url: string, data: any) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "x-api-key": "H8YCJG3-CR84MSN-H0AZTKP-GXQXG7Z", //prod
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
}
