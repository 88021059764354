import { STORES } from "constant";
import BaseLayout from "layouts/BaseLayout";
import Cart from "pages/Cart";
import Checkout from "pages/Checkout";
import CheckoutFail from "pages/Checkout/Fail";
import CheckoutSuccess from "pages/Checkout/Success";
import EWalletPage from "pages/EWallet";
import Home from "pages/Home";
import Product from "pages/Product";
import { useCallback, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { getCountryByIp } from "services/getCountryByIp";
import { useStore } from "stores";

function App() {
  return (
    <BaseLayout>
      <Routes>
        <Route path={Home.path} element={<Home />} />
        <Route path={`${Product.path}/:id`} element={<Product />} />
        <Route path={Cart.path} element={<Cart />} />
        <Route path={Checkout.path} element={<Checkout />} />
        <Route path={CheckoutSuccess.path} element={<CheckoutSuccess />} />
        <Route path={CheckoutFail.path} element={<CheckoutFail />} />
        <Route path={EWalletPage.path} element={<EWalletPage />} />
      </Routes>
    </BaseLayout>
  );
}

export default App;
