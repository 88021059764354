import Button from 'components/common/Button'
import { useQuery } from 'hooks/useQuery'
import Home from 'pages/Home'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Checkout from '.'

type Props = {}

const CheckoutFail = (props: Props) => {
  const navigate = useNavigate()
  const qs = useQuery()

  useEffect(() => {
    if (qs.get('status') !== 'fail') navigate(Home.path)
  }, [qs])

  return (
    <div className="text-center mx-16 my-8">
      <div className="font-medium mb-4">Payment Failed.</div>
      <Button className="w-full" onClick={() => navigate(Home.path)}>
        Return to home page
      </Button>
    </div>
  )
}

CheckoutFail.path = `${Checkout.path}/fail`

export default CheckoutFail
