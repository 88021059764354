import toast from "react-hot-toast"
import { useStore } from "stores"

export const checkout = async (payload: {
  amount: string
  remarks: string
}) => {
  const { store } = useStore.getState()

  const formattedPayload = {
    ...payload,
    amount: (parseFloat(payload?.amount) * store.usdRate).toFixed(2),
    reference:
      new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
    canceledUrl: `${window.location.origin}/checkout`,
    successUrl: `${window.location.origin}/checkout/success?status=success`,
    failedUrl: `${window.location.origin}/checkout/fail?status=fail`,
  }

  try {
    const res = await fetch(
      "https://payment.pay44.io/api/v1/shop/createOrder",
      {
        method: "POST",
        headers: {
          "x-api-key": "H8YCJG3-CR84MSN-H0AZTKP-GXQXG7Z",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedPayload),
      }
    )
    const resData = await res.json()
    return resData.result
  } catch (error) {
    toast.error("Something went wrong! Unable to checkout.")
    return false
  }
}
