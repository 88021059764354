export const PRODUCTS = [
  {
    id: 1,
    title: "Apple Watch",
    image: "apple-watch.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 4,
  },
  {
    id: 2,
    title: "Audemars Piguet",
    image: "audemars-piguet.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 5,
  },
  {
    id: 3,
    title: "Car",
    image: "car.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 5,
  },
  {
    id: 4,
    title: "Chocolate Cake",
    image: "chocolate-cake.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 4,
  },
  {
    id: 5,
    title: "Rolex Day Date",
    image: "rolex-oyster-perpetual-day-date.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 5,
  },
  {
    id: 6,
    title: "Bag",
    image: "bag.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 5,
  },
  {
    id: 7,
    title: "Bracelet",
    image: "bracelet.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 4,
  },
  {
    id: 8,
    title: "Skateboard",
    image: "skateboard.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi dignissimos ab neque earum optio distinctio velit aut, error id incidunt maxime saepe tempora quae quia quod expedita illo asperiores nihil.",
    price: "1.00",
    stars: 4,
  },
];

export const STORES = [
  {
    name: "US",
    value: "SG-S-NPQPBAWN2N6J",
    currency: "USD",
    code: "US",
    apiKey: "3NA7268-AG84RQW-GE9WDKE-5EAYPSS",
    rate: 1,
    usdRate: 1,
  },
];
