import { customFetch } from './../utils/index'
import toast from 'react-hot-toast'

export const deposit = async () => {
  const formattedPayload = {
    reference: `deposit - ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
    remarks: 'demo-store deposit',
  }

  try {
    const res = await customFetch(
      "https://payment.pay44.io/api/v1/wallet/deposit",
      // 'http://localhost:3099/v1/wallet/deposit',
      formattedPayload
    )
    const resData = await res.json()
    return resData.result
  } catch (error) {
    toast.error('Something went wrong! Unable to deposit.')
    return false
  }
}
