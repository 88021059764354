import Button from 'components/common/Button'
import { useState } from 'react'
import { deposit } from 'services/deposit'
import { withdrawal } from 'services/withdrawal'

type Props = {}

const EWalletPage = (props: Props) => {
  const [selectedCurrency, setSelectedCurrency] = useState('TRX_USDT')
  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const handleWithdrawal = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as any
    setIsWithdrawing(true)
    const res = await withdrawal({
      amount: form.amount.value,
      address: form.address.value,
      currency: selectedCurrency,
    })
    setIsWithdrawing(false)
    if (res) {
      form.amount.value = ''
      form.address.value = ''
      setSelectedCurrency('TRX_USDT')
    }
  }

  const handleDeposit = async () => {
    const res = await deposit()
    if (res.url) {
      window.location = res.url
    }
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="border rounded flex justify-center items-centerh min-h-[150px]">
          <Button className="m-auto" onClick={handleDeposit}>
            Deposit
          </Button>
        </div>
        <form onSubmit={handleWithdrawal}>
          <div className="flex flex-col space-y-4 border rounded h-full p-4">
            <div>
              <label
                htmlFor="currency"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Select a currency
              </label>
              <select
                id="currency"
                value={selectedCurrency}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                <option selected value="TRX_USDT">
                  USDT TRC20
                </option>
                <option value="USDT">USDT ERC20 </option>
              </select>
            </div>

            <div>
              <label
                htmlFor="amount"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Amount to withdraw
              </label>
              <div className="flex">
                <input
                  id="amount"
                  type="number"
                  className="rounded-none rounded-l-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  step='any'
                />
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-r-md border border-l-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  {selectedCurrency === 'TRX_USDT'
                    ? 'USDT TRC20'
                    : 'USDT ERC20'}
                </span>
              </div>
            </div>
            <div>
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Recipient wallet address
              </label>
              <input
                id="address"
                type="text"
                className="rounded-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <Button disabled={isWithdrawing}>{isWithdrawing ? 'Loading...' : 'Withdrawal'}</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

EWalletPage.path = '/e-wallet'

export default EWalletPage