import toast from 'react-hot-toast'
import { customFetch } from 'utils'

export const withdrawal = async (payload: {
  amount: string
  address: string
  currency: string
}) => {
  const formattedPayload = {
    ...payload,
    username: 'demo-store',
    withdrawal_id:
      new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
  }

  try {
    const res = await customFetch(
      "https://payment.pay44.io/api/v1/withdrawal",
      // 'http://localhost:3099/v1/withdrawal',
      formattedPayload
    )
    const resData = await res.json()
    if (resData.errors) {
      let errorStr = ''
      Object.keys(resData.errors).forEach((key) => {
        errorStr += resData.errors[key] + '\n'
      })
      toast.error(errorStr)
      return false
    }
    if (resData.reason) {
      toast.error(resData.reason)
      return false
    }
    toast.success('Your withdrawal request has been sent.')
    return true
  } catch (error) {
    toast.error('Something went wrong! Unable to create withdrawal request.')
    return false
  }
}
